@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');

.nav-title {
  font-size: 30px;
}

@media (max-width: 323px) {
  .nav-title {
    font-size: 7.25vw;
  }
}

.navbar-toggler.active,
.navbar-toggler-icon.active {
  outline: none;
  box-shadow: none;
  color: white;
}

.ms-auto {
  color: white;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 700 !important;
  margin-right: 50px;
}

@media (max-width: 991px) {
  .navbar-nav,
  .navbar-nav.ms-auto-collapsed {
    margin-left: 30px !important;
  }
}

.navbar-blur {
  position: relative;
  z-index: 1;
  transition: top 0.3s;
}

.navbar-blur.show {
  top: 0;
}

.navbar-blur.hide {
  top: -150px; /* Adjust this value if needed */
}

.navbar-blur::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  background-color: rgba(255, 255, 255, 0.02); /* Optional: adjust the opacity */
  z-index: -1;
}

.nav-link:hover {
  color: pink !important;
}

@media (min-width: 992px) {
  .nav-link {
    font-size: 20px !important;
  }
}

@media (min-width: 1200px) {
  .nav-link {
    font-size: 25px !important;
  }
}
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #04fcfc 0%, #fc0471 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-content {
  text-align: center;
}

.loading-image {
  width: 200px;
  height: auto;
  animation: pulse 2s ease-in-out infinite;
}

@keyframes pulse {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.1); }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  margin-top: 20px;
}

.bar-container {
  perspective: 1000px;
  margin: 0 10px;
}

.bar {
  width: auto; /* Adjust based on your image size */
  height: 150px; /* Adjust based on your image size */
  object-fit: contain;
  transform-style: preserve-3d;
  transform: rotateX(-20deg) rotateY(-30deg);
  animation: barAnimation 1.5s infinite ease-in-out;
  margin-top: 100px;
}

.bar1 {
  animation-delay: 0s;
}

.bar2 {
  animation-delay: -0.5s;
  margin-left: -200px;
  margin-right: -200px;
}

.bar3 {
  animation-delay: -1s;
}

@keyframes barAnimation {
  0%, 100% { transform: rotateX(-20deg) rotateY(-30deg) translateY(20px); }
  50% { transform: rotateX(-20deg) rotateY(-30deg) translateY(-20px); }
}
/* src/components/Home.css */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bevan:ital@0;1&display=swap');
/* .home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(0deg, #14284d 0%, #3979e9 100%);
} */
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: url('../../public/Images/blankbg.png') no-repeat center center;
  background-size: cover;
}
/* @media(max-width:558px) {
  .home{
    background: url('./blankbg2.jpg') no-repeat center center;
    background-size: cover;

  }
} */

.title{
  font-family: "Bevan", serif;
  font-weight: 400;
  font-style: normal;
}
@media (min-width: 450px) and (max-height: 800px) {
  .home {
    height: 1000px;
  }
}
.centered-content {
  text-align: center;
  animation: fadeIn 4s;
}

.title {
  font-size: 7vw;
  color: white;
  /* font-family: 'Poppins', sans-serif; */
  position: relative;
  bottom: 0px;
  margin-top: 150px;
  text-shadow: 
    -2px -2px 0 #000,  
    2px -2px 0 #000,
    -2px 2px 0 #000,
    2px 2px 0 #000,
    3px 3px 0 #000,
    4px 4px 0 #000,
    5px 5px 0 #000;
    color: white;
}

@media (min-width: 2424px) {
  .title{
    font-size: 169.68px;
  }
}
/* @media(max-width:700px){
  .title{
    text-align: left;
    margin-left: 0px;
  }
  .title span {
    display: block;
  }
} */
.button {
  visibility: hidden;
  outline: none;
  transition: all 0.3s ease;
  font-size: 2vw;
  /* padding: 20px; */
  border-radius: 40px;
  border: 2px solid transparent;
  background: transparent;
  color: white;
  cursor: pointer;
  font-family: 'Quicksand', sans-serif;
  /* font-family: 'Poppins', sans-serif; */

  position: relative;
  top: 260px;
}

.button:hover {
  /* background-color: white; */
  /* color: black; */
  transform: scale(1.4);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}

.gradient-section {
  padding: 50px 0;
}

.gradient-section-1 {
  /* background: linear-gradient(180deg,#af88e2 0% , #4c43f0 100%); */
  /*  #f3aaf0   #af88e2 */
  background: linear-gradient(180deg, #eb2572 0%,#b64fb3 100%);

}

.gradient-section-2 {
  background: linear-gradient(180deg, #b64fb3  0%,#af88e2  100%);
}
.gradient-section-3 {
  background: linear-gradient(180deg, #af88e2 0%, #6a269e 100%);
}

.gradient-section-4 {
  background: linear-gradient(180deg, #6a269e  0%,#4c43f0  100%);
}

.gradient-section-5 {
  background: linear-gradient(180deg,#4c43f0 0% , #0259fa 100%);
}




.gradient-section-6 {
  background: linear-gradient(180deg, #0259fa 0%, #00aaff 100%);
}

/* @media (min-width: 3200px) {
  .title {
    font-size: 250px;
  }
  .button {
    font-size: 120px;
  }
} */

@media (max-width: 700px) {
  .title {
    font-size: 12vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .title span {
    display: block;
  }
  .button {
    font-size: 5vw;
  }
}

@media (max-width: 400px) {
  .button {
    font-size: 6vw;
  }
}
/* Styles for the new button */
.image-button {
  width:500px; /* Adjust the size as needed */
  height:500px; /* Adjust the size as needed */
  background: url('../../public/Images/arborblack5.png') no-repeat center center;
  background-size: contain; /* Use contain to ensure the image fits within the button */
  border: none;
  cursor: pointer;
  transition: background 0.5s ease;
  margin-top: 20px; /* Adjust spacing as needed */
  position: relative;
  /* right: -150px; */
  
}

.image-button:hover {
  background: url('../../public/Images/viewmenu7.png') no-repeat center center;
  background-size: contain; /* Ensure the hover image also fits within the button */
  /* transform: scale(1.4); */

}
.view-butt{
  visibility: hidden;
}
@media (max-width: 698px) {
  .image-button{
    height: 270px;
    width: 270px;
  }
}
@media(max-height: 670px) {
  .image-button{
    top: -50px;
  }
}

@media(max-width: 550px) {
  .view-butt{
    visibility: visible;
    position: relative;
    bottom: 118px;
    outline: none;
  transition: all 0.3s ease;
  font-size: 6vw;
  /* padding 20px; */
  border-radius: 40px;
  border: 2px solid transparent;
  background: transparent;
  color: white;
  cursor: pointer;
  font-family: 'Quicksand', sans-serif;
  }
  .view-butt:hover {
    /* background-color: white; */
    /* color: black; */
    transform: scale(1.4);
    /* box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2); */
  }

}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
.view-butt {
  animation: bounce 1s infinite;
}
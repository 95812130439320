@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');

.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Align items at the start to ensure alignment */
  background-color: black;
  color: white;
  padding: 50px;
  flex-wrap: wrap;
  font-family: 'Poppins', sans-serif;
}

.footer-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.footer-section h3 {
  margin-bottom: 10px;
}

.footer-section img {
  margin-top: 10px;
}

.footer-links {
  list-style-type: none; /* Remove bullet points */
  padding: 0;
  margin: 0;
}

.footer-links li {
  margin: 5px 0;
}

.footer-links a {
  color: white; /* Ensure links are white */
  text-decoration: none; /* Remove underlines */
  transition: color 0.3s;
}

.footer-links a:hover {
  color: pink; /* Change to pink on hover */
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .footer-section {
    margin-bottom: 20px;
    text-align: center;
    justify-content: center;
  }
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bevan:ital@0;1&display=swap');




.lineage {
  width: 100%;
  margin-bottom: 15px;
}

.lineage span {
  display: block;
  font-style: italic;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.sale-tag {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 70px;
  height: auto;
}

.flowerr {
  font-family: 'Poppins', sans-serif;
  font-weight: 700 !important;
}

.flowertit {
  font-size: 1.50em !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600 !important;
  margin-bottom: 0.5rem;
}

.flower-title {
  font-family: "Bevan", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 100px;
  color: white;
  text-shadow: 
    -2px -2px 0 #000,  
    2px -2px 0 #000,
    -2px 2px 0 #000,
    2px 2px 0 #000,
    3px 3px 0 #000,
    4px 4px 0 #000,
    5px 5px 0 #000;
  color: white;
}

.price-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding: 0 10px; /* Add some padding to prevent text from touching the edges */
}

.price-item {
  font-size: 1.15em;
  color: white;
  text-align: center;
  flex: 1;
}

.price-item.full {
  font-size: 1.55em !important;
  font-weight: bold;
  flex: 1; /* Give more space to the full price */
  margin: 0 !important; /* Remove any existing margins */
  
}
@media (max-width: 1445px) and (min-width: 992px) {
  .price-item {
    font-size: .90em;
    color: white;
    text-align: center;
    flex: 1;
  }
  
  .price-item.full {
    font-size: 1.25em !important;
    font-weight: bold;
    flex: 2; /* Give more space to the full price */
    margin: 0 !important; /* Remove any existing margins */
    
  }
  .flowertit{
    font-size: 1.35em !important;
  }
  .product-title-item{
    font-size: 1.25em !important;
  }
}
@media(max-width:440px) {
  /* .price-item{
    font-size: .85em;
  } */
  .price-item.full{
    flex:2;
    /* font-size: 1.25em !important; */
  }
}
@media(max-width:350px) {
  .price-item{
    font-size: .85em;
  }
  .price-item.full{
    flex:2;
    font-size: 1.25em !important;
  }
}
.one-price {
  justify-content: center;
  font-size: 1.5em;
}

.two-prices {
  justify-content: center;
}

.two-prices .price-item {
  margin: 0 20px;
  font-size: 1.5em;
}

.three-prices .price-item.full {
  font-size: 1.35em;
}

.card {
  margin: 20px;
  border: none;
  background-color: black !important;
  color: white;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.card:hover {
  transform: scale(1.05);
}

.card-image-container {
  position: relative;
  cursor: pointer;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.flower-card-img-top {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease-in-out;
  transform: scale(0.875);
}

.card-description {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 20px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  overflow-y: auto;
}

.card-description .lineage,
.card-description .description {
  margin-bottom: 15px;
  font-size: 1em;
  width: 100%;
}

.card-description strong {
  color: #FFD700;
  display: block;
  margin-bottom: 8px;
  font-size: 1.5em;
}

.card-description .lineage span,
.card-description .description span {
  font-style: italic;
  display: block;
}

.card-description .description span {
  width: calc(100% - 40px);
  margin: 0 auto;
  padding: 0 20px;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 0;
}

.card.show-description .card-img-top {
  opacity: 0;
}

.card.show-description .card-description {
  opacity: 1;
}

.justify-content-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.justify-content-center > [class*='col-'] {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px; /* This adds space between rows */
}

@media (max-width: 767px) {
  .card:hover {
    transform: none;
  }
}

@media (max-width: 600px) {
  .flower-title {
    font-size: 60px;
  }
}

@media (min-width: 1500px) {
  .flowerr {
    padding: 0 5%;
  }
}

@media (min-width: 1900px) {
  .flowerr {
    padding: 0 10%;
  }
}

@media (min-width: 2400px) {
  .flowerr {
    padding: 0 15%;
  }
}

@media (max-width: 1700px) {
  .card-description .lineage span,
  .card-description .description span {
    font-size: 0.9vw;
  }
}

@media(max-width:991px) {
  .card-description .lineage span,
  .card-description .description span {
    font-size: 1.45vw;
  }
}

@media(max-width:767px) {
  .card-description .lineage span,
  .card-description .description span {
    font-size: 3.2vw;
  }
  .card-description strong {
    font-size: 1.7em;
  }
}

@media(max-width:420px) {
  .card-description .lineage span,
  .card-description .description span {
    font-size: 3vw;
  }
  .card-description strong {
    font-size: 1.2em;
  }
}

@media(max-width:335px) {
  .card-description .lineage span,
  .card-description .description span {
    font-size: 2.75vw;
  }
  .card-description strong {
    font-size: 1.2em;
  }
  
  .flowertit {
    font-size: 1.250em !important;
  }
}


.strain-name{
  font-size: 1.4em;
}
.card-text{
  font-size: 1.3em;
}

@media(max-width:1609px) {
  .strain-name{
    font-size: 1.2em;
  }
  .card-text{
    font-size: 1.1em;
  }
}
@media(max-width:1140px) {
  .strain-name{
    font-size: 1em;
  }
  .card-text{
    font-size: .9em;
  }
}
@media(max-width:991px) {
  .strain-name{
    font-size: 1.3em;
  }
  .card-text{
    font-size: 1.1em;
  }
}

@media(max-width:875px) {
  .strain-name{
    font-size: 1.1em;
  }
  .card-text{
    font-size: .9em;
  }
}
@media(max-width:767px) {
  .strain-name{
    font-size: 2em;
  }
  .card-text{
    font-size: 1.6em;
  }
}
@media(max-width:665px) {
  .strain-name{
    font-size: 1.75em;
  }
  .card-text{
    font-size: 1.5em;
  }
}
@media(max-width:592px) {
  .strain-name{
    font-size: 1.5em;
  }
  .card-text{
    font-size: 1.25em;
  }
}
@media(max-width:497px) {
  .strain-name{
    font-size: 1.25em;
  }
  .card-text{
    font-size: 1em;
  }
}
@media(max-width:415px) {
  .strain-name{
    font-size: 6.5vw;
  }
  .card-text{
    font-size: 1em;
  }
}
@import url('https://fonts.googleapis.com/css2?family=Acme&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lilita+One&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arsenal+SC:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.solv-title {
    font-family: "Lilita One", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 4.5rem; 
    margin-bottom: 25px;
    text-shadow: 
    -2px -2px 0 #000,  
    2px -2px 0 #000,
    -2px 2px 0 #000,
    2px 2px 0 #000,
    3px 3px 0 #000,
    4px 4px 0 #000,
    5px 5px 0 #000;
    color: white;
}

.brand {
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
    color: white;
    text-shadow: 3px 3px 0 #000;
    -webkit-text-stroke: 1px black;
    font-size: 2.5rem !important;
}

.stuff {
  font-family: 'Quicksand', sans-serif;
  line-height: 1.1;
  margin-bottom: 5px;
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.strain-price-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 5px;
}

.center-wrapper {
    position: relative;
    display: inline-flex;
    align-items: center;
}

.solvstrain {
    font-size: 1.5em;
    font-family: "Arsenal SC", sans-serif;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    color: black;
    text-shadow: 
        2.5px 2.5px 2px rgba(255,255,255,0.7),
        -2.5px -2.5px 2px rgba(0,0,0,0.3);
}

.solvprice {
    font-size: 1em;
    position: absolute;
    left: calc(100% + 10px);
    top: 0;
    white-space: nowrap;
    font-weight: 900;
    display: flex;
    align-items: center;
    color: black;
}

.solvprice .quantity {
    color: black;
    text-shadow: 
        0 0 2px rgba(255, 255, 255, 0.8),
        0 0 4px rgba(255, 255, 255, 0.8),
        0 0 6px rgba(255, 255, 255, 0.8);
    margin-right: 3px; /* Add space between quantity and price */

}

.solvprice .price {
    color: black;
    text-shadow: 
        0 0 2px rgba(255, 255, 255, 0.8),
        0 0 4px rgba(255, 255, 255, 0.8),
        0 0 6px rgba(255, 255, 255, 0.8);
}

.solvprice.special-price .price {
    color: white;
    text-shadow: 
        0 0 2px rgba(151, 0, 177, 0.8),
        0 0 4px rgba(151, 0, 177, 0.8),
        0 0 6px rgba(151, 0, 177, 0.8);
}

.solvprice span {
    display: inline-block;
}

.solvprice img {
    width: 40px;
    margin-left: 10px;
    vertical-align: middle;
    height: auto;
}

.fresh-press {
    position: absolute;
    right: calc(100% + 5px);
    top: 0px;
    width: 40px;
}

.solvlineage {
    margin: 0;
    text-align: center;
    font-style: italic;
}

.brand-price {
    font-size: 1.2em;
    margin-bottom: 15px;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    text-shadow: 
        -1px -1px 0 #ffffffc1,  
         1px -1px 0 #ffffffc1,
        -1px  1px 0 #ffffffc1,
         1px  1px 0 #ffffffc1;
}
  
.brand-price span {
    margin: 0 5px;
}

.smaller-text {
    font-size: 0.8em;
}

.solvbg {
    border-color: black;
    border-width: 5px;
    border-style: solid;
    border-radius: 20px;
    background: linear-gradient(135deg, rgba(4, 252, 252, 1) 0%, rgba(4, 252, 61, 1) 100%, rgba(252, 4, 113, 0.88) 100%);
    max-width: 800px;
    padding-top: 50px;
    padding-bottom: 50px;
}

@media (max-width: 800px) {
    .solvbg {
        border-width: 2px;
    }
}

@media(max-width:600px) { 
    .solv-title{
        font-size: 3.75rem;
    }
}

@media(max-width:500px) { 
    .solv-title{
        font-size: 3rem;
    }
    .brand{
        font-size: 2.15rem !important;
    }
}

@media(max-width:400px) { 
    .solv-title{
        font-size: 2.6rem;
    }
    .brand{
        font-size: 1.8rem !important;
    }
}

@media(max-width:353px) { 
    .solv-title{
        font-size: 2.25rem;
    }
    .brand-price{
        font-size: 1.1rem;
    }
    .brand{
        font-size: 1.5rem !important;
    }
}

@media(max-width:312px) { 
    .brand-price{
        font-size: .8rem;
    }
}

@media(max-width:300px) {
    .solv-title{
        font-size: 11vw;
    }
}

@media(max-width:517px) { 
    .brand{
     font-size: 1.4rem;
    }
    .solvstrain{
     font-size: 1.3rem;
    }
    .solvprice{
     font-size: .75rem;
    }
    .solvlineage{
     font-size: .8rem;
    }
    .fresh-press{
     width: 37px;
     top: -2px;
    }
    .solvprice img {
    width: 37px;
   }
 }
 
 @media(max-width:435px) { 
     .brand{
      font-size: 1.2rem;
     }
     .solvstrain{
      font-size: 1.1rem;
     }
     .solvprice{
      font-size: .6rem;
     }
     .solvlineage{
      font-size: .7rem;
     }
     .fresh-press{
         width: 33px;
        }
        .solvprice img {
        width: 33px;
     }
  }
 
  @media(max-width:375px) { 
     .brand{
      font-size: 1rem;
     }
     .solvstrain{
      font-size: .9rem;
     }
     .solvprice{
      font-size: .4rem;
     }
     .solvlineage{
      font-size: .6rem;
     }
     .fresh-press{
         width: 28px;
        }
        .solvprice img {
        width: 28px;
     }
  }